import { makeAutoObservable } from "mobx";
import axios from "axios";

import UserStore from "./UserStore";
import { triggerEvent } from "../constants/utils";

class PremiumStore {
  PREMIUM_PLANS = [
    {
      id: 1,
      duration: "1 month",
      amount: "1499",
      price: 14.99,
      perDayPrice: 0.5,
      currency: "USD",
      currencySymbol: "$",
    },
    {
      id: 2,
      duration: "3 months",
      amount: "1999",
      price: 19.99,
      perDayPrice: 0.2,
      strikeThroughPrice: 39.99,
      currency: "USD",
      currencySymbol: "$",
    },
    {
      id: 3,
      duration: "1 year",
      amount: "2999",
      price: 29.99,
      perDayPrice: 0.1,
      strikeThroughPrice: 99.99,
      currency: "USD",
      currencySymbol: "$",
      offer: "save 70%",
    },
  ];
  selectedPremiumPlan = this.PREMIUM_PLANS[2];
  razorpayOrderId = null;

  constructor() {
    makeAutoObservable(this);
  }

  handlePlanSelection = (planObj) => {
    this.selectedPremiumPlan = planObj;
    triggerEvent("WTA-Premium_Plan_Select", {
      plan: planObj.price,
    });
  };

  createRazorpayOrder = async () => {
    const response = await axios
      .post(
        `https://evolve-web-onboarding-backend-server-641054470672.us-central1.run.app/orders/${this.selectedPremiumPlan.amount}/rec_${UserStore.developerId}`
      )
      .then((result) => {
        return result;
      })
      .catch((error) => {
        console.error(error);
      });

    const data = response.data;
    this.razorpayOrderId = data.id;
  };

  handlePaymentCheckout = async (Razorpay, navigate) => {
    triggerEvent("WTA-Purchase_Button", {
      plan: this.selectedPremiumPlan.price,
    });

    try {
      await this.createRazorpayOrder();

      const options = {
        debug: true,
        key: process.env.REACT_APP_RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
        amount: this.selectedPremiumPlan.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "USD",
        name: "Evolve",
        description: UserStore.developerId,
        order_id: this.razorpayOrderId,
        handler: function () {
          navigate("/signup");
          UserStore.markUserPremium();
          triggerEvent("WTA-Purchase", {
            value: this.selectedPremiumPlan.price,
            currency: "USD",
          });
        },
        theme: {
          color: "#3399cc",
        },
        prefill: {
          email: UserStore.email,
        },
        readonly: {
          email: true,
        },
      };

      const rzp1 = new Razorpay(options);

      rzp1.on("payment.failed", function (response) {
        alert(response.error.description);
        navigate("/signup");
      });

      rzp1.open();
    } catch (error) {
      navigate("/signup");
      console.error(error);
    }
  };
}

export default new PremiumStore();
