import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";
import { makeAutoObservable } from "mobx";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";

import { PREMIUM_PLANS } from "../screens/Premium/config";
import { triggerEvent } from "../constants/utils";

class UserStore {
  email = "";
  password = "1234";
  developerId = "";
  isEmailValid = true;
  deviceId = uuidv4();
  accessToken = "";
  premiumPlan = PREMIUM_PLANS[0];

  constructor() {
    makeAutoObservable(this);
  }

  setFields = (key, value) => {
    this[key] = value;
  };

  onSelectPremiumPlan = (item) => {
    this.setFields("premiumPlan", item);
    triggerEvent("Premium_Plan_Select", {
      plan: item.price,
    });
  };

  createRazorpayOrder = async () => {
    const response = await axios
      .post(
        `https://evolve-web-onboarding-backend-server-641054470672.us-central1.run.app/orders/${this.premiumPlan.amount}/rec_${this.email}`
      )
      .then((result) => {
        triggerEvent("Razorpay_Order_Created");
        return result;
      })
      .catch((error) => {
        console.error(error);
      });

    const data = response.data;
    this.razorpayOrderId = data.id;
  };

  handlePaymentCheckout = async (Razorpay) => {
    triggerEvent("Purchase_Button", {
      plan: this.premiumPlan.price,
    });
    try {
      await this.createRazorpayOrder();

      const options = {
        debug: true,
        key: process.env.REACT_APP_RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
        amount: this.premiumPlan.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "USD",
        name: "Evolve",
        description: ".",
        order_id: this.razorpayOrderId,
        handler: function () {
          window.location.href =
            "https://calendly.com/palkee-embracelives/embrace-adhd-assessment";
          ReactPixel.track("Purchase", {
            value: this.premiumPlan.price,
            currency: "USD",
          });
          ReactGA.event("Purchase", {
            value: this.premiumPlan.price,
            currency: "USD",
          });
        },
        theme: {
          color: "#3399cc",
        },
        prefill: {
          email: this.email,
        },
        readonly: {
          email: true,
        },
      };

      const rzp1 = new Razorpay(options);

      rzp1.on("payment.failed", function (response) {
        alert(response.error.description);
      });

      rzp1.open();
    } catch (error) {
      console.error(error);
    }
  };
}

export default new UserStore();
